import React from 'react'
import { BannerContainer, ImgContainer, TextContainer } from './style.js'
import { ComponentContainer } from '../../utils/globalStyledComponents.js'
import { Link } from 'gatsby'
import { AvenueImg } from '../AvenueImg'

const HomeBanner = ({ highlightBanner, secondary }) => {
  return (
    <div>
      {/* <Link to={highlightBanner.link.uri}> */}
      <a
        href="https://www.ballarat.vic.gov.au/about-us/council-elections-2024"
        target="_link"
        rel="noopener noreferrer"
      >
        <BannerContainer secondary={secondary}>
          <ImgContainer secondary={secondary}>
            <AvenueImg
              fluid={highlightBanner.image}
              alt={highlightBanner.heading}
            />
          </ImgContainer>
          <TextContainer secondary={secondary}>
            <h2>{highlightBanner.heading}</h2>
            <h5>{highlightBanner.linkText}</h5>
          </TextContainer>
        </BannerContainer>
      </a>
    </div>
  )
}

export default HomeBanner
